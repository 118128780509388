import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment/min/moment-with-locales";
import { useNavigate } from "react-router";
import MakdosTable from "components/MakdosTable";
import { useGlobalStore } from "store/GlobalStore";

function ComplaintNotificationTable({ abuseList, ongetAbuseList, numberOfPages }) {
  const { t, i18n } = useTranslation(["projects", "global"]);
  const [data, setData] = useState(abuseList);
  const navigate = useNavigate();

  const [pageLimit] = useGlobalStore((state) => [state.pageLimit]);
  const pageLimits = [10, 25, 50, 100];
  const [activePageSize, setActivePageSize] = useState(pageLimit);

  const columns = React.useMemo(() => [
    {
      Header: "Konu",
      accessor: "Subject"
    },

    {
      Header: "Tarih",
      accessor: "recieve_time",
      Cell: (props) =>
        props.value !== null
          ? moment(props.value).format("DD-MM-YYYY HH:mm")
          : "-"
    },
    {
      Header: "Okundu",
      accessor: "read_status",
      Cell: (props) =>
        props.value === true ? "Evet" : "Hayır"
    },
    {
      Header: "IP Adresi",
      accessor: "ip_address"
    },
    {
      Header: "",
      accessor: "col6",
      Cell: tableButton
    }
  ], [t]);

  function tableButton(props) {
    if (props) {
      return (
        <div
          onClick={() => {
            navigate(`/complaint-notification-details/${props.row.original.id}`);
          }}
          className="flex items-center cursor-pointer"
        >
          <i className="ri-eye-line curs"></i>
        </div>
      );
    }
  }

  useEffect(() => {
    setData(abuseList);
  }, [abuseList]);
  return (
    <div>
      {abuseList && abuseList !== "ticket_not_found" &&
        <div className="w-full mb-12">
          <MakdosTable
            isCheckboxVisible={false}
            setCheckList={() => {
            }}
            checkList={[]}
            data={data}
            setData={setData}
            showPagination={true}
            columns={columns}
            pageLimits={pageLimits}
            isBackendPagination={true}
            backendPaginationMethod={(page, limit) => {
              ongetAbuseList(page, limit);
            }}
            backendNumberOfPages={numberOfPages}
            activePageSize={activePageSize}
            setActivePageSize={setActivePageSize}
            isDndAvailable={false}
          />
        </div>
      }

    </div>
  );
}

export default ComplaintNotificationTable;