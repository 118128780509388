import { useTranslation } from "react-i18next";
import AllNotificationTable from "./TicketAndSupportTables/AllNotificationTable";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useGlobalStore } from "store/GlobalStore";
import { listAllNotification } from "services/allServices";

const AllNotification = () => {
    const { t } = useTranslation(["support", "global"]);
    const location = useLocation();
    const [pageLimit] = useGlobalStore((state) => [state.pageLimit]);

    const [notificationList, setNotificationList] = useState([]);
    const [numberOfPages, setNumberOfPages] = useState(1);

    const onlistAllNotification = async (page, limit) => {
        listAllNotification(page, limit).then(async (data) => {
            if (data.status === "ticket_not_found") {
                await setNotificationList([]);
                setNumberOfPages(0);
            } else {
                let res = await data.notification_list
                await setNotificationList(res);
                setNumberOfPages(data.number_of_pages);
            }
        });
    }

    useEffect(() => {
        const asyncFunction = async () => {
            let page = 1;
            if (location?.search) {
                page = location.search.split("=")[1];
            }
            onlistAllNotification(page, pageLimit);
        };
        asyncFunction();
    }, []);

    return (
        <div>
            <div className="items-center justify-between lg:flex">
                <h2 className="my-5 font-semibold">{t("support:allNotification")}</h2>
            </div>

            <div className="overflow-x-auto">
                <AllNotificationTable notificationList={notificationList} onlistAllNotification={onlistAllNotification} numberOfPages={numberOfPages} />
            </div>

        </div>
    )
}

export default AllNotification