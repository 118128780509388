import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { listTicketAbuseDetail } from "services/allServices";
import moment from "moment";
import { useGlobalStore } from "store/GlobalStore";

function ComplaintNotificationDetails() {
  const params = useParams();

  const [notificationDetail, setNotificationDetail] = useState();
  const [notificationCount, setNotificationCount] = useGlobalStore((state) => [state.notificationCount, state.setNotificationCount]);

  const onlistTicketAbuseDetail = async (id) => {
    await listTicketAbuseDetail(id).then((res) => {
      setNotificationDetail(res);
    })
  }

  useEffect(() => {
    onlistTicketAbuseDetail(params.id)
    setNotificationCount(notificationCount - 1)
  }, [params.id])

  return (
    <div className="container">
      {/* <h2 className="font-semibold text-title">
        E-posta sunucunuz spam maili çıkıyor
      </h2> */}
      <div className="w-full p-8 mt-8 bg-white rounded-md">
        <h1>
          {notificationDetail?.Subject}
        </h1>

        <div className="mt-10">
          <p className="font-bold text-title">
            Gönderme Tarihi: {moment(notificationDetail?.recieve_time).format("DD-MM-YYYY HH:mm:ss")}
          </p>
          <p className="font-bold text-title">
            Gönderen: {notificationDetail?.email}
          </p>
          <p className="font-bold text-title">Sunucu IP: {notificationDetail?.ip_address} </p>
        </div>

        <div className="bg-passive w-full h-0.5 my-10"></div>

        <div className="mb-5 break-words whitespace-pre-wrap">
          {notificationDetail?.body}
        </div>
      </div>
    </div>
  );
}

export default ComplaintNotificationDetails;
