import create from "zustand";
import { devtools } from "zustand/middleware";
import { AES, enc } from "crypto-js";

let decryptMessage = (code) => {
  let secretKey = process.env.REACT_APP_CRYPTO_KEY;
  if (localStorage.getItem("__u_per")) {
    try {
      let decryptedData = AES.decrypt(code, secretKey).toString(enc.Utf8);
      return (decryptedData);
    } catch (err) {
      // window.location.href = "/";
    }
  }

};

export const useGlobalStore = create(
  devtools((set, get) => ({
    selectedPageTab:
      JSON.parse(localStorage.getItem("__selectedPageTab")) || "1",
    setSelectedPageTab: (value) => {
      set((state) => ({
        selectedPageTab: value
      }));

      get().addToLocalStorageGlobal();
    },

    // Bu kısım sidebardan ödeme bildiri seçildiği zaman ödeme bildiriminin seçili gelmesi için
    supportRequestType: "1",
    setSupportRequestType: (value) => {
      set((state) => ({
        supportRequestType: value
      }));

      get().addToLocalStorageGlobal();
    },

    // Bu kısım sidebardan ödeme bildiri seçildiği zaman modalın açılması için
    opensupportRequest: false,
    setOpensupportRequest: (value) => {
      set((state) => ({
        opensupportRequest: value
      }));

      get().addToLocalStorageGlobal();
    },

    //seçilen para tipi
    currencyType: "₺",
    setCurrencyType: (value) => {
      set((state) => ({
        currencyType: value
      }));
      get().addToLocalStorageGlobal();
    },

    //kur oranı
    currencyRates: {},
    setCurrencyRates: (value) => {
      set((state) => ({
        currencyRates: value
      }));
      get().addToLocalStorageGlobal();
    },

    refreshSidebar: false,
    setRefreshSidebar: (value) => {
      set((state) => ({
        refreshSidebar: value
      }));
      get().addToLocalStorageGlobal();
    },


    pageLimit: parseInt(localStorage.getItem("__pageLimit")) || 25,
    setPageLimit: (value) => {
      set((state) => ({
        pageLimit: (typeof value !== "undefined") ? parseInt(value) : ""
      }));
      get().addToLocalStorageGlobal();
    },

    proformaFilterData: JSON.parse(localStorage.getItem("__proformaFilterData")) || "",
    setProformaFilterData: (value) => {
      set((state) => ({
        proformaFilterData: value
      }));
      get().addToLocalStorageGlobal();
    },

    invoicesFilterData: JSON.parse(localStorage.getItem("__invoicesFilterData")) || "",
    setInvoicesFilterData: (value) => {
      set((state) => ({
        invoicesFilterData: value
      }));
      get().addToLocalStorageGlobal();
    },

    domainsFilterData: JSON.parse(localStorage.getItem("__domainsFilterData")) || "",
    setDomainsFilterData: (value) => {
      set((state) => ({
        domainsFilterData: value
      }));
      get().addToLocalStorageGlobal();
    },

    controlPanelFilterData: JSON.parse(localStorage.getItem("__controlPanelFilterData")) || "",
    setControlPanelFilterData: (value) => {
      set((state) => ({
        controlPanelFilterData: value
      }));
      get().addToLocalStorageGlobal();
    },

    sslCertificateFilterData: JSON.parse(localStorage.getItem("__sslCertificateFilterData")) || "",
    setSslCertificateFilterData: (value) => {
      set((state) => ({
        sslCertificateFilterData: value
      }));
      get().addToLocalStorageGlobal();
    },

    colocationFilterData: JSON.parse(localStorage.getItem("__colocationFilterData")) || "",
    setColocationFilterData: (value) => {
      set((state) => ({
        colocationFilterData: value
      }));
      get().addToLocalStorageGlobal();
    },

    ipFilterData: JSON.parse(localStorage.getItem("__ipFilterData")) || "",
    setIpFilterData: (value) => {
      set((state) => ({
        ipFilterData: value
      }));
      get().addToLocalStorageGlobal();
    },

    supportFilterData: JSON.parse(localStorage.getItem("__supportFilterData")) || "",
    setSupportFilterData: (value) => {
      set((state) => ({
        supportFilterData: value
      }));
      get().addToLocalStorageGlobal();
    },

    iysFilterData: JSON.parse(localStorage.getItem("__iysFilterData")) || "",
    setIYSFilterData: (value) => {
      set((state) => ({
        iysFilterData: value
      }));
      get().addToLocalStorageGlobal();
    },

    firewallFilterData: JSON.parse(localStorage.getItem("__firewallFilterData")) || "",
    setFirewallFilterData: (value) => {
      set((state) => ({
        firewallFilterData: value
      }));
      get().addToLocalStorageGlobal();
    },

    proxyFilterData: JSON.parse(localStorage.getItem("__proxyFilterData")) || "",
    setProxyFilterData: (value) => {
      set((state) => ({
        proxyFilterData: value
      }));
      get().addToLocalStorageGlobal();
    },

    mailHostingFilterData: JSON.parse(localStorage.getItem("__mailHostingFilterData")) || "",
    setMailHostingFilterData: (value) => {
      set((state) => ({
        mailHostingFilterData: value
      }));
      get().addToLocalStorageGlobal();
    },

    webHostingFilterData: JSON.parse(localStorage.getItem("__webHostingFilterData")) || "",
    setWebHostingFilterData: (value) => {
      set((state) => ({
        webHostingFilterData: value
      }));
      get().addToLocalStorageGlobal();
    },

    physicalServersFilterData: JSON.parse(localStorage.getItem("__physicalServersFilterData")) || "",
    setPhysicalServersFilterData: (value) => {
      set((state) => ({
        physicalServersFilterData: value
      }));
      get().addToLocalStorageGlobal();
    },

    cloudServersFilterData: JSON.parse(localStorage.getItem("__cloudServersFilterData")) || "",
    setCloudServersFilterData: (value) => {
      set((state) => ({
        cloudServersFilterData: value
      }));
      get().addToLocalStorageGlobal();
    },

    virtualServersFilterData: JSON.parse(localStorage.getItem("__virtualServersFilterData")) || "",
    setVirtualServersFilterData: (value) => {
      set((state) => ({
        virtualServersFilterData: value
      }));
      get().addToLocalStorageGlobal();
    },

    paymentHistoryFilterData: JSON.parse(localStorage.getItem("__paymentHistoryFilterData")) || "",
    setPaymentHistoryFilterData: (value) => {
      set((state) => ({
        paymentHistoryFilterData: value
      }));
      get().addToLocalStorageGlobal();
    },

    ticketFilterData: JSON.parse(localStorage.getItem("__ticketFilterData")) || "",
    setTicketFilterData: (value) => {
      set((state) => ({
        ticketFilterData: value
      }));
      get().addToLocalStorageGlobal();
    },

    ipRecordsFilterData: JSON.parse(localStorage.getItem("__ipRecordsFilterData")) || "",
    setIPRecordsFilterData: (value) => {
      set((state) => ({
        ipRecordsFilterData: value
      }));
      get().addToLocalStorageGlobal();
    },

    logsFilterData: JSON.parse(localStorage.getItem("__logsFilterData")) || "",
    setLogsFilterData: (value) => {
      set((state) => ({
        logsFilterData: value
      }));
      get().addToLocalStorageGlobal();
    },


    permissions: (localStorage.getItem("__u_per") && localStorage.getItem("__u_per") !== "") ? JSON.parse(decryptMessage(localStorage.getItem("__u_per"))) : [],
    setPermissions: (value) => {
      set((state) => ({
        permissions: (typeof value !== "undefined") ? value : ""
      }));
    },

    isResponsible: localStorage.getItem("__u_is_resp") ? localStorage.getItem("__u_is_resp") : false,
    setIsResponsible: (value) => {
      set((state) => ({
        isResponsible: (typeof value !== "undefined") ? value : ""
      }));
    },

    notificationCount: parseInt(localStorage.getItem("__n_count")) || 0,
    setNotificationCount: (value) => {
      set((state) => ({
        notificationCount: (typeof value !== "undefined") ? parseInt(value) : ""
      }));
      get().addToLocalStorageGlobal();
    },


    resetAll: () => {
      set((state) => ({
        selectedPageTab: "1",
        supportRequestType: "1",
        opensupportRequest: false,
        currencyType: "₺",
        currencyRates: {},
        pageLimit: 25,
        proformaFilterData: "",
        invoicesFilterData: "",
        domainsFilterData: "",
        controlPanelFilterData: "",
        ipFilterData: "",
        supportFilterData: "",
        iysFilterData: "",
        firewallFilterData: "",
        proxyFilterData: "",
        sslCertificateFilterData: "",
        colocationFilterData: "",
        mailHostingFilterData: "",
        webHostingFilterData: "",
        physicalServersFilterData: "",
        cloudServersFilterData: "",
        virtualServersFilterData: "",
        paymentHistoryFilterData: "",
        ticketFilterData: "",
        ipRecordsFilterData: "",
        logsFilterData: "",
        isResponsible: false,
        notificationCount: 0
      }))
    },

    addToLocalStorageGlobal: () => {
      // localStorage.removeItem("__products")
      localStorage.setItem(
        "__selectedPageTab",
        JSON.stringify(get().selectedPageTab)
      );
      localStorage.setItem(
        "__supportRequestType",
        JSON.stringify(get().supportRequestType)
      );
      localStorage.setItem(
        "__opensupportRequest",
        JSON.stringify(get().opensupportRequest)
      );

      localStorage.setItem(
        "__currencyType",
        JSON.stringify(get().currencyType)
      );
      localStorage.setItem(
        "__currencyRates",
        JSON.stringify(get().currencyRates)
      );

      localStorage.setItem(
        "__pageLimit",
        JSON.stringify(get().pageLimit)
      );

      localStorage.setItem(
        "__proformaFilterData",
        JSON.stringify(get().proformaFilterData)
      );

      localStorage.setItem(
        "__invoicesFilterData",
        JSON.stringify(get().invoicesFilterData)
      );

      localStorage.setItem(
        "__domainsFilterData",
        JSON.stringify(get().domainsFilterData)
      );

      localStorage.setItem(
        "__controlPanelFilterData",
        JSON.stringify(get().controlPanelFilterData)
      );

      localStorage.setItem(
        "__ipFilterData",
        JSON.stringify(get().ipFilterData)
      );

      localStorage.setItem(
        "__supportFilterData",
        JSON.stringify(get().supportFilterData)
      );

      localStorage.setItem(
        "__iysFilterData",
        JSON.stringify(get().iysFilterData)
      );

      localStorage.setItem(
        "__firewallFilterData",
        JSON.stringify(get().firewallFilterData)
      );

      localStorage.setItem(
        "__proxyFilterData",
        JSON.stringify(get().proxyFilterData)
      );

      localStorage.setItem(
        "__sslCertificateFilterData",
        JSON.stringify(get().sslCertificateFilterData)
      );

      localStorage.setItem(
        "__colocationFilterData",
        JSON.stringify(get().colocationFilterData)
      );

      localStorage.setItem(
        "__mailHostingFilterData",
        JSON.stringify(get().mailHostingFilterData)
      );

      localStorage.setItem(
        "__webHostingFilterData",
        JSON.stringify(get().webHostingFilterData)
      );

      localStorage.setItem(
        "__physicalServersFilterData",
        JSON.stringify(get().physicalServersFilterData)
      );

      localStorage.setItem(
        "__cloudServersFilterData",
        JSON.stringify(get().cloudServersFilterData)
      );
      localStorage.setItem(
        "__virtualServersFilterData",
        JSON.stringify(get().virtualServersFilterData)
      );

      localStorage.setItem(
        "__paymentHistoryFilterData",
        JSON.stringify(get().paymentHistoryFilterData)
      );

      localStorage.setItem(
        "__ticketFilterData",
        JSON.stringify(get().ticketFilterData)
      );

      localStorage.setItem(
        "__ipRecordsFilterData",
        JSON.stringify(get().ipRecordsFilterData)
      );
      localStorage.setItem(
        "__logsFilterData",
        JSON.stringify(get().logsFilterData)
      );

      localStorage.setItem(
        "__u_is_resp",
        JSON.stringify(get().isResponsible)
      );

      localStorage.setItem(
        "__n_count",
        JSON.stringify(get().notificationCount)
      );

    },
    checkLocalStorageGlobal: () => {
      if (
        typeof localStorage.getItem("__selectedPageTab") !== "undefined" &&
        localStorage.getItem("__selectedPageTab") !== null &&
        localStorage.getItem("__selectedPageTab") !== ""
      )
        set((state) => ({
          selectedPageTab: JSON.parse(
            localStorage.getItem("__selectedPageTab")
          )
        }));

      if (
        typeof localStorage.getItem("__supportRequestType") !== "undefined" &&
        localStorage.getItem("__supportRequestType") !== null &&
        localStorage.getItem("__supportRequestType") !== ""
      )
        set((state) => ({
          supportRequestType: JSON.parse(
            localStorage.getItem("__supportRequestType")
          )
        }));
      if (
        typeof localStorage.getItem("__opensupportRequest") !== "undefined" &&
        localStorage.getItem("__opensupportRequest") !== null &&
        localStorage.getItem("__opensupportRequest") !== ""
      )
        set((state) => ({
          opensupportRequest: JSON.parse(
            localStorage.getItem("__opensupportRequest")
          )
        }));

      if (
        typeof localStorage.getItem("__currencyType") !== "undefined" &&
        localStorage.getItem("__currencyType") !== null &&
        localStorage.getItem("__currencyType") !== ""
      )
        set((state) => ({
          currencyType: JSON.parse(localStorage.getItem("__currencyType"))
        }));
      if (
        typeof localStorage.getItem("__currencyRates") !== "undefined" &&
        localStorage.getItem("__currencyRates") !== null &&
        localStorage.getItem("__currencyRates") !== ""
      )
        set((state) => ({
          currencyRates: JSON.parse(localStorage.getItem("__currencyRates"))
        }));

      if (
        typeof localStorage.getItem("__pageLimit") !== "undefined" &&
        localStorage.getItem("__pageLimit") !== null &&
        localStorage.getItem("__pageLimit") !== ""
      )
        set((state) => ({
          pageLimit: JSON.parse(localStorage.getItem("__pageLimit"))
        }));

      if (
        typeof localStorage.getItem("__proformaFilterData") !== "undefined" &&
        localStorage.getItem("__proformaFilterData") !== null &&
        localStorage.getItem("__proformaFilterData") !== ""
      )
        set((state) => ({
          proformaFilterData: JSON.parse(localStorage.getItem("__proformaFilterData"))
        }));

      if (
        typeof localStorage.getItem("__invoicesFilterData") !== "undefined" &&
        localStorage.getItem("__invoicesFilterData") !== null &&
        localStorage.getItem("__invoicesFilterData") !== ""
      )
        set((state) => ({
          invoicesFilterData: JSON.parse(localStorage.getItem("__invoicesFilterData"))
        }));

      if (typeof localStorage.getItem("__domainsFilterData") !== "undefined" &&
        localStorage.getItem("__domainsFilterData") !== null &&
        localStorage.getItem("__domainsFilterData") !== ""
      ) {
        set((state) => ({
          domainsFilterData: JSON.parse(localStorage.getItem("__domainsFilterData"))
        }))
      }

      if (typeof localStorage.getItem("__controlPanelFilterData") !== "undefined" &&
        localStorage.getItem("__controlPanelFilterData") !== null &&
        localStorage.getItem("__controlPanelFilterData") !== ""
      ) {
        set((state) => ({
          controlPanelFilterData: JSON.parse(localStorage.getItem("__controlPanelFilterData"))
        }))
      }

      if (typeof localStorage.getItem("__ipFilterData") !== "undefined" &&
        localStorage.getItem("__ipFilterData") !== null &&
        localStorage.getItem("__ipFilterData") !== ""
      ) {
        set((state) => ({
          ipFilterData: JSON.parse(localStorage.getItem("__ipFilterData"))
        }))
      }

      if (typeof localStorage.getItem("__supportFilterData") !== "undefined" &&
        localStorage.getItem("__supportFilterData") !== null &&
        localStorage.getItem("__supportFilterData") !== ""
      ) {
        set((state) => ({
          supportFilterData: JSON.parse(localStorage.getItem("__supportFilterData"))
        }))
      }

      if (typeof localStorage.getItem("__iysFilterData") !== "undefined" &&
        localStorage.getItem("__iysFilterData") !== null &&
        localStorage.getItem("__iysFilterData") !== ""
      ) {
        set((state) => ({
          iysFilterData: JSON.parse(localStorage.getItem("__iysFilterData"))
        }))
      }

      if (typeof localStorage.getItem("__firewallFilterData") !== "undefined" &&
        localStorage.getItem("__firewallFilterData") !== null &&
        localStorage.getItem("__firewallFilterData") !== ""
      ) {
        set((state) => ({
          firewallFilterData: JSON.parse(localStorage.getItem("__firewallFilterData"))
        }))
      }

      if (typeof localStorage.getItem("__proxyFilterData") !== "undefined" &&
        localStorage.getItem("__proxyFilterData") !== null &&
        localStorage.getItem("__proxyFilterData") !== ""
      ) {
        set((state) => ({
          proxyFilterData: JSON.parse(localStorage.getItem("__proxyFilterData"))
        }))
      }

      if (typeof localStorage.getItem("__sslCertificateFilterData") !== "undefined" &&
        localStorage.getItem("__sslCertificateFilterData") !== null &&
        localStorage.getItem("__sslCertificateFilterData") !== ""
      ) {
        set((state) => ({
          sslCertificateFilterData: JSON.parse(localStorage.getItem("__sslCertificateFilterData"))
        }))
      }

      if (typeof localStorage.getItem("__colocationFilterData") !== "undefined" &&
        localStorage.getItem("__colocationFilterData") !== null &&
        localStorage.getItem("__colocationFilterData") !== ""
      ) {
        set((state) => ({
          colocationFilterData: JSON.parse(localStorage.getItem("__colocationFilterData"))
        }))
      }

      if (typeof localStorage.getItem("__mailHostingFilterData") !== "undefined" &&
        localStorage.getItem("__mailHostingFilterData") !== null &&
        localStorage.getItem("__mailHostingFilterData") !== ""
      ) {
        set((state) => ({
          mailHostingFilterData: JSON.parse(localStorage.getItem("__mailHostingFilterData"))
        }))
      }

      if (typeof localStorage.getItem("__webHostingFilterData") !== "undefined" &&
        localStorage.getItem("__webHostingFilterData") !== null &&
        localStorage.getItem("__webHostingFilterData") !== ""
      ) {
        set((state) => ({
          webHostingFilterData: JSON.parse(localStorage.getItem("__webHostingFilterData"))
        }))
      }

      if (typeof localStorage.getItem("__physicalServersFilterData") !== "undefined" &&
        localStorage.getItem("__physicalServersFilterData") !== null &&
        localStorage.getItem("__physicalServersFilterData") !== ""
      ) {
        set((state) => ({
          physicalServersFilterData: JSON.parse(localStorage.getItem("__physicalServersFilterData"))
        }))
      }

      if (typeof localStorage.getItem("__cloudServersFilterData") !== "undefined" &&
        localStorage.getItem("__cloudServersFilterData") !== null &&
        localStorage.getItem("__cloudServersFilterData") !== ""
      ) {
        set((state) => ({
          cloudServersFilterData: JSON.parse(localStorage.getItem("__cloudServersFilterData"))
        }))
      }

      // if (
      //   typeof localStorage.getItem("__virtualServersFilterData") !== "undefined" &&
      //   localStorage.getItem("__virtualServersFilterData") !== null &&
      //   localStorage.getItem("__virtualServersFilterData") !== ""
      // ) {
      //   set((state) => ({
      //     virtualServersFilterData: JSON.parse(localStorage.getItem("__virtualServersFilterData"))
      //   }))
      // }

      if (
        typeof localStorage.getItem("__paymentHistoryFilterData") !== "undefined" &&
        localStorage.getItem("__paymentHistoryFilterData") !== null &&
        localStorage.getItem("__paymentHistoryFilterData") !== ""
      )
        set((state) => ({
          paymentHistoryFilterData: JSON.parse(localStorage.getItem("__paymentHistoryFilterData"))
        }));

      if (
        typeof localStorage.getItem("__ticketFilterData") !== "undefined" &&
        localStorage.getItem("__ticketFilterData") !== null &&
        localStorage.getItem("__ticketFilterData") !== ""
      )
        set((state) => ({
          ticketFilterData: JSON.parse(localStorage.getItem("__ticketFilterData"))
        }));

      if (
        typeof localStorage.getItem("__ipRecordsFilterData") !== "undefined" &&
        localStorage.getItem("__ipRecordsFilterData") !== null &&
        localStorage.getItem("__ipRecordsFilterData") !== ""
      )
        set((state) => ({
          ipRecordsFilterData: JSON.parse(localStorage.getItem("__ipRecordsFilterData"))
        }));

      if (
        typeof localStorage.getItem("__n_count") !== "undefined" &&
        localStorage.getItem("__n_count") !== null &&
        localStorage.getItem("__n_count") !== ""
      )
        set((state) => ({
          notificationCount: JSON.parse(localStorage.getItem("__n_count"))
        }));
    }
  }))
);
