import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getAbuseList, readAllAbuseNotification } from "services/allServices";
import { TailSpin } from "react-loader-spinner";
import NewSupportRequestModal from "./TicketAndSupportModals/NewSupportRequestModal";
import ComplaintNotificationTable from "./TicketAndSupportTables/ComplaintNotificationTable";
import ComplaintNotificationFilterModal from "./TicketAndSupportModals/ComplaintNotificationFilterModal";
import Button from "components/FormElements/Button";
import { useToasts } from "react-toast-notifications";
import { useGlobalStore } from "store/GlobalStore";
import { combineIDContent } from "helpers/helpersFunc";
import { useLocation } from "react-router";

function ComplaintNotification() {
  const { t } = useTranslation(["support", "global"]);
  const { addToast } = useToasts();
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [openNewSupportRequestModal, setOpenNewSupportRequestModal] = useState(false);
  const [abuseList, setAbuseList] = useState([]);
  const [pageLimit] = useGlobalStore((state) => [state.pageLimit]);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const location = useLocation();

  const [setNotificationCount] = useGlobalStore((state) => [state.setNotificationCount]);
  const params = abuseList.filter((item) => !item.read_status).map((item) => item.id)

  const handleReadAllNotification = async (page) => {

    await readAllAbuseNotification(params).then(async (res) => {
      if (res.status === "created") {
        setNotificationCount(0)
        ongetAbuseList(page, pageLimit)
        addToast(t("support:markedReadAll"), {
          appearance: "success",
          autoDismiss: true
        });
      } else {
        addToast(t("support:occurredError"), {
          appearance: "error",
          autoDismiss: true
        });
      }
    })
  }

  const ongetAbuseList = async (page, limit) => {
    getAbuseList(page, limit).then(async (data) => {
      let res = await combineIDContent(data.abuse_reports)
      await setAbuseList(res);
      setNumberOfPages(data.number_of_pages);
    });
  }

  useEffect(() => {
    const asyncFunction = async () => {
      let page = 1;
      if (location?.search) {
        page = location.search.split("=")[1];
      }
      ongetAbuseList(page, pageLimit);
    };
    asyncFunction();
  }, []);

  return (
    <div>
      <div className="items-center justify-between lg:flex">
        <h2 className="my-5 font-semibold">{t("support:complaintNotification")}</h2>
        {params?.length > 0 && <div>
          <Button
            onClick={() => handleReadAllNotification()}
            className="flex items-center mb-4 px-7"
            secondary
            icon
            dark
          >
            <i className="ri-checkbox-circle-line" />
            <span>{t("support:readAll")}</span>
          </Button>
        </div>}
      </div>
      <div className="overflow-x-auto">


        {abuseList && abuseList?.status !== "ticket_not_found" ? (
          <ComplaintNotificationTable abuseList={abuseList} ongetAbuseList={ongetAbuseList} numberOfPages={numberOfPages} />
        ) : abuseList && abuseList?.status === "ticket_not_found" ? (
          <div className="flex flex-col items-center justify-center my-10 lg:my-20">
            <img src="/ticketNotFound.svg" width="572" height="288" />
            <h1 className="mt-6">{t("support:noComplaintNotification")}</h1>
            <h5>{t("support:noComplaintNotificationDesc")}</h5>
          </div>
        )
          : (
            <div className="flex items-center justify-center w-full mt-20">
              <TailSpin ariaLabel="tail-spin-loading" radius="1" visible={true}
                type="Oval"
                color="#ff7c30"
                height={75}
                width={75}
                timeout={3000}
              />
            </div>
          )}
      </div>

      {openNewSupportRequestModal && (
        <NewSupportRequestModal
          setOpenModal={() => setOpenNewSupportRequestModal(false)}
        />
      )}
      {openFilterModal && (
        <ComplaintNotificationFilterModal
          setOpenModal={() => setOpenFilterModal(false)}
        />
      )}
    </div>
  );
}

export default ComplaintNotification;