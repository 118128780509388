import MakdosTable from "components/MakdosTable";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useGlobalStore } from "store/GlobalStore";

const AllNotificationTable = ({ notificationList, onlistAllNotification, numberOfPages }) => {
    const { t, i18n } = useTranslation(["projects", "global"]);
    const [data, setData] = useState(notificationList);
    const navigate = useNavigate();

    const [pageLimit] = useGlobalStore((state) => [state.pageLimit]);
    const pageLimits = [10, 25, 50, 100];
    const [activePageSize, setActivePageSize] = useState(pageLimit);

    const columns = useMemo(() => [
        {
            Header: t("global:subject"),
            accessor: "Subject"
        },

        {
            Header: t("global:type"),
            accessor: "type",
        },
        {
            Header: t("global:read"),
            accessor: "read_status",
            Cell: (props) =>
                props.value === true ? "Evet" : "Hayır"
        },
        {
            Header: "IP Adresi",
            accessor: "ip_address"
        },
        {
            Header: "",
            accessor: "col6",
            Cell: tableButton
        }
    ], [t]);

    function tableButton(props) {
        if (props) {
            return (
                <div
                    onClick={() => {
                        navigate(`/complaint-notification-details/${props.row.original.id}`);
                    }}
                    className="flex items-center cursor-pointer"
                >
                    <i className="ri-eye-line curs"></i>
                </div>
            );
        }
    }

    useEffect(() => {
        setData(notificationList);
    }, [notificationList]);

    return (
        <div>
            <div className="w-full mb-12">
                <MakdosTable
                    isCheckboxVisible={false}
                    setCheckList={() => {
                    }}
                    checkList={[]}
                    data={data}
                    setData={setData}
                    showPagination={true}
                    columns={columns}
                    pageLimits={pageLimits}
                    isBackendPagination={true}
                    backendPaginationMethod={(page, limit) => {
                        onlistAllNotification(page, limit);
                    }}
                    backendNumberOfPages={numberOfPages}
                    activePageSize={activePageSize}
                    setActivePageSize={setActivePageSize}
                    isDndAvailable={false}
                />
            </div>
        </div>
    )
}

export default AllNotificationTable