import axios from "axios";
import api from "./api";
import { combineIDContent } from "helpers/helpersFunc/index";
import api_admin from "./api_admin";

//let frontpageApi = "https://api-web.makdos.com/"
// let frontpageApi = "http://0.0.0.0:8004/"
let frontpageApi = process.env.REACT_APP_FRONTPAGE_URL;
let ipApiUrl = process.env.REACT_APP_API_IP_URL;


//#region Auth

export async function logout() {
  const res = await api.get(`auth/logout`);
  return res.data;
}

export async function tokenCheck(token) {
  const res = await api.post(`auth/token-check`, { token: token });
  return res.data;
}

export async function checkLoginMail(email) {

  const res = await api.post(`auth/check/mail`, { email: email });
  return res.data;
}

export async function beforeLogin(data) {
  const res = await api.post(`auth/before/login`, data);
  return res.data;
}

export async function beforeLoginCheck(data) {
  const res = await api.post(`auth/check/before/login`, data);
  return res.data;
}

export async function login(data, headers) {
  const res = await api.post(`auth/login`, data, headers);
  return res.data;
}


export async function activityControl(data) {
  const res = await api.post(`auth/activity/control`, data);
  return res.data;
}

export async function checkRedirectToken(token) {
  const res = await api.post(`auth/check/redirect/token`, { token: token });
  return res.data;
}

export async function forgotPasswordSendCode(email, code, phone, type) {
  const res = await api.post(`${frontpageApi}forgot/password/send/code?email=${email}&code=${code}&phone=${phone}&sent_code_via=${type}`);
  return res.data;
}

export async function forgotPasswordCheckCode(email, code, pass1, pass2) {
  const res = await api.post(`${frontpageApi}forgot/password/check/code?email=${email}&code=${code}&new_password_1=${pass1}&new_password_2=${pass2}`);
  return res.data;
}

export async function getRedirectToken(id) {
  const res = await api.get(`auth/get/redirect/token?customer_id=${id}`);
  return res.data;
}

export async function passwordCheck(params) {
  const res = await axios.post(`${frontpageApi}register/password/check`, { content: params });
  return res.data;
}


export async function accountDisableSendCode() {
  const res = await api.delete(`account/disable`);
  return res.data;
}

export async function accountDisableCheckCode(type, code) {
  const res = await api.get(`/account/disable/check?activation_type=${type}&activation_code=${code}`);
  return res.data;
}






//#endregion


//#region Homepage


export async function getCustomerCredit() {
  const res = await api.get(`/accounting/get/credits`);
  return res.data;
}


export async function getHomepageCounts() {
  const res = await api.get(`/get/dashboard/info`);
  return res.data;
}

//#endregion


//#region Profile Settings

export async function getCountry() {
  const res = await axios.get(`${frontpageApi}cache/country/list`);
  return await combineIDContent(res?.data);
}

export async function getCountryStates(countryCode) {
  const res = await axios.get(
    `${frontpageApi}cache/state/by/country/list?country_code=${countryCode}`
  );
  if (res.data?.status !== "record_not_found") {
    return await combineIDContent(res?.data);

  } else {
    return res.data;
  }
}

export async function getCities(countryCode, stateCode) {
  const res = await axios.get(
    `${frontpageApi}cache/city/by/country/and/state/list?country_code=${countryCode}&state_code=${stateCode}`
  );
  if (res.data?.status !== "record_not_found") {
    return await combineIDContent(res?.data);

  } else {
    return res.data;
  }

}


export async function getInvoicesList(page = 1, limit = 25, params) {
  const res = await api.post(`/accounting/invoices/list?page=${page}&limit=${limit}`, { content: params });
  return res.data;
}

export async function getInvoice(invoice_id) {
  const res = await api.get(
    `/accounting/invoices/get?invoice_id=${invoice_id}`
  );
  return res.data;
}

export async function getOwnInfo(columns) {
  const res = await api.get(
    `/get/own/info?selected_columns=${columns}`
  );
  return res.data;
}

export async function getBillingInfoList() {
  const res = await api.get(`/billing/info/get`);
  return combineIDContent(res?.data);
}

export async function createBillingInfo(data) {
  const res = await api.post(`/billing/info/create`, { content: data });
  return res.data;
}

export async function updateBillingInfo(billing_info_id, data) {
  const res = await api.put(`/billing/info/update?billing_info_id=${billing_info_id}`, { content: data });
  return res.data;
}

export async function deleteBillingInfo(billing_info_id) {
  const res = await api.delete(`/billing/info/delete?billing_info_id=${billing_info_id}`);
  return res.data;
}


export async function getNotificationAndNews() {
  const res = await api.get(`/get/notification/news`);
  return res.data;

}

export async function updateNotificationAndNews(value) {
  const res = await api.put(`/update/notification/news`, value);
  return res.data;

}

export async function checkCustomerActivation() {
  const res = await api.get(`/check/activision`);
  return res.data;
}

export async function sendCustomerActivationCode() {
  const res = await api.post(`/send/activision/code`);
  return res.data;
}

export async function checkCustomerActivationCode(code) {
  const res = await api.post(`/check/activision/code?code=${code}`);
  return res.data;
}

export async function getMailSendingInfos() {
  const res = await api.get(`/get/mail/sending/infos`);
  return res.data;
}

export async function updateMailSendingInfos(data) {
  const res = await api.put(`/update/mail/sending/infos`, { content: data });
  return res.data;
}

export async function updatePasswordSendCode(old_password) {
  const res = await api.post('/staff/update/password/send/code', { content: { old_password: old_password } });
  return res.data;
}

export async function updatePasswordCheckCode(code, old_password, new_password) {
  const res = await api.post(`/staff/update/password/check/code?code=${code}&old_password=${old_password}&new_password=${new_password}`);
  return res.data;
}

export async function getResponsibleList() {
  const res = await api.get(`/staff/list`);
  return await combineIDContent(res.data);
}

export async function getDoubleValidationInfo() {
  const res = await api.get(`/get/staff/tfa/info`);
  return res.data;
}

export async function sendDoubleValidationInfoCode(data) {
  const res = await api.post(`/update/staff/tfa/info/send/code`, { content: data });
  return res.data;
}

export async function checkDoubleValidationInfoCode(code, data) {
  const res = await api.post(`/update/staff/tfa/info/check/code?code=${code}`, { content: data });
  return res.data;
}

export async function listAuthorizedAccount() {
  const res = await api.get(`/list/staff/authorized_account`);
  return res.data;
}


export async function getLanguageList() {
  const res = await api.get(`/get/language`);
  return combineIDContent(res.data);
}

export async function updateTicketLanguage(language_shortcut) {
  const res = await api.get(`/update/ticket/language?language_shortcut=${language_shortcut}`);
  return res.data;
}


export async function listAuthorizedStaff() {
  const res = await api.get(`authorized/staff/list`);
  return res.data;
}


export async function createAuthorizedStaff(params) {
  const res = await api.post(`authorized/staff/create`, { content: params });
  return res.data;
}

export async function updateAuthorizedStaff(id, params) {
  const res = await api.put(`authorized/staff/update?authorized_account_id=${id}`, { content: params });
  return res.data;
}

export async function deleteAuthorizedStaff(id) {
  const res = await api.delete(`authorized/staff/delete?authorized_account_id=${id}`);
  return res.data;
}

export async function listCustomerLogs(page, limit, params) {
  const res = await api.post(`logs/list?page=${page}&limit=${limit}`, { content: params });
  return res.data;
}

//#endregion


//#region Projects

export async function getProjectList() {
  const res = await api.get(`/project/get`);
  return await combineIDContent(res?.data);
}

export async function getProject(id) {
  const res = await api.get(`/project/get?id_=${id}`);
  return await combineIDContent(res?.data);
}

export async function getProjectBySlug(slug) {
  const res = await api.get(`/project/get?slug=${slug}`);
  if (res.data === null) {
    return res.data;
  } else {
    return await combineIDContent(res?.data);
  }
}


export async function getPurposeList() {
  const res = await api.get(`/project/purpose/get`);
  return await combineIDContent(res?.data);
}


export async function createProjectPurpose(values) {
  const res = await api.post(`/project/purpose/create`, { content: values });
  return res.data;
}

export async function updateProjectPurpose(project_purpose_id, values) {
  const res = await api.put(`/project/purpose/update?project_purpose_id=${project_purpose_id}`, { content: values });
  return res.data;
}

export async function checkDeleteProjectPurpose(project_purpose_id) {
  const res = await api.get(`/project/purpose/delete/check?project_purpose_id=${project_purpose_id}`);
  return res.data;
}

export async function deleteProjectPurpose(project_purpose_id) {
  const res = await api.delete(`/project/purpose/delete?project_purpose_id=${project_purpose_id}`);
  return res.data;
}

export async function createProject(values) {
  const res = await api.post(`/project/create`, { content: values });
  return res.data;
}

export async function updateProject(id, values) {
  const res = await api.put(`/project/update?project_id=${id}`, { content: values });
  return res.data;
}

export async function deleteProject(id) {
  const res = await api.delete(`/project/delete?project_id=${id}`);
  return res.data;
}

//#endregion


//#region Tickets And Supports

export async function ticketCreate(value) {
  const res = await api.post(`/ticket/create`, { content: value });
  return res.data;
}

export async function getTicketList(page = 1, limit = 25, filter) {
  const res = await api.post(`/ticket/list?page=${page}&limit=${limit}`, { content: filter });
  return res.data;
}

export async function getTicketDepartmentsList() {
  const res = await api.get(`/ticket/departments/list`);
  let combine = await combineIDContent(res.data[0].get_or_list);
  return combine;
}

export async function getTicketCategoriesList(department_id) {
  const res = await api.get(
    `/ticket/categories/list?department_id=${department_id}`
  );
  return res.data;
}

export async function getTicketOwnedProductList(project_id) {
  const res = await api.get(`/ticket/owned/product/all/list${project_id ? `?project_id=${project_id}` : ""}`);
  return res.data;
}

export async function getTicketMessages(id) {
  const res = await api.get(`/ticket/detail?ticket_id=${id}`);
  return res.data;
}

export async function ticketReplyCreate(data) {
  const res = await api.post(`/ticket/reply/create`, { content: data });
  return res.data;
}

export async function ticketAddAttachment(datas) {
  const res = await api.post(
    `/ticket/add/attachment`,
    { content: datas }
  );
  return res.data;
}

export async function getTicketAttachmentList(ticket_id, reply_id) {
  const res = await api.get(
    `/ticket/attachments/list?ticket_id=${ticket_id}&reply_id=${reply_id}`
  );
  if (res.data?.status !== "attachments_not_found" && res.data?.status !== "ticket_not_found") {
    return await combineIDContent(res.data);
  }
  return res.data;
}


export async function ticketSendDecrypt(data) {
  const res = await api.post(`/ticket/send/decrypt/code`, { content: data });
  return res.data;
}

export async function ticketSingleReply(data) {
  const res = await api.post(`/ticket/decrypt/single/reply`, data);
  return res.data;
}

export async function ticketAllReply(ticket_id, auth_key) {
  const res = await api.get(
    `/ticket/decrypt/all/reply?ticket_id=${ticket_id}&auth_key=${auth_key}`
  );
  return res.data;
}


export async function ticketEncrypt(ticket_id, reply_id) {
  const res = await api.post(`/ticket/encrypt/single/reply?ticket_id=${ticket_id}&reply_id=${reply_id}`);
  return res.data;
}

export async function closeTicket(ticket_id) {
  const res = await api.get(
    `/ticket/close/ticket?ticket_id=${ticket_id}`
  );
  return res.data;
}

export async function addBankTransferBalance(data) {
  const res = await api.post(
    `/accounting/add/bank/transfer/balance`,
    { content: data }
  );
  return res.data;
}

export async function ticketCreatePaymentNotification(data) {
  const res = await api.post(
    `/ticket/create/payment/notification`,
    { content: data }
  );
  return res.data;
}

export async function createPaymentNotificationProforma(values) {
  const res = await api.post(`/ticket/create/payment/notification/from/proforma`, values);
  return res.data;
}

export async function ticketAddFile(file) {
  const res = await axios.post(`${process.env.REACT_APP_NEW_API_FILE_URL}/files`, file);
  return res.data;
}

export async function getAbuseList(page, limit) {
  const res = await api.get(`/ticket/abuse/record/list?page=${page}&limit=${limit}`);
  if (res.data?.status !== "ticket_not_found") {
    return await res.data;
  } else {
    return res.data;
  }

}

export async function listTicketStaffRating(ticket_id) {
  const res = await api.get(`/ticket/get/staff/rating?ticket_id=${ticket_id}`);

  return res.data;
}

export async function createTicketStaffRating(ticket_id, params) {
  const res = await api.post(`ticket/create/staff/rating?ticket_id=${ticket_id}`, { content: params });

  return res.data;
}

export async function getInformationCenterData(params) {
  const res = await api.post(`knowledge/center`, { content: params });
  return res.data;
}

export async function listTicketNotificationCount() {
  const res = await api.get(`ticket/notification/count`);
  return res.data;
}

export async function listTicketAbuseDetail(id) {
  const res = await api.get(`ticket/abuse/record/detail?abuse_id=${id}`);
  return combineIDContent(res.data);
}

export async function readNotification(params) {
  const res = await api.post(`ticket/notification/read`, params);
  return res.data;
}

export async function readAllAbuseNotification(params) {
  const res = await api.post(`ticket/abuse/record/read`, params);
  return res.data;
}

export async function listAllNotification(page, limit) {
  const res = await api.get(`ticket/notification/list/all?page=${page}&limit=${limit}`);
  return res.data;
}

//#endregion


//#region Product Services

export async function listProductAll() {
  const res = await api.get(`/accounting/owned/product/all/list`);
  return res.data;
}

export async function listProductAllWithProjects(id) {
  const res = await api.get(`/accounting/owned/product/by/projectid/list?project_id=${id}`);
  return res.data;
}

export async function listProductCloudServers(params) {
  const res = await api.post(`/accounting/owned/product/cloudservers/list`, params);
  return res.data;
}

export async function listProductClouds(params) {
  const res = await api.post(`/accounting/owned/product/cloud/list`, params);
  return res.data;
}

export async function listProductDedicatedServers(params) {
  const res = await api.post(`/accounting/owned/product/dedicatedservers/list`, params);
  return res.data;
}

export async function listProductDomains(params) {
  const res = await api.post(`/accounting/owned/product/domains/list`, params);
  return res.data;
}

export async function listProductWebHostings(params) {
  const res = await api.post(`/accounting/owned/product/webhostings/list`, params);
  return res.data;
}

export async function listProductMailHostings(params) {
  const res = await api.post(`/accounting/owned/product/mailhosting/list`, params);
  return res.data;
}

export async function listProductCoLocations(params) {
  const res = await api.post(`/accounting/owned/product/colocation/list`, params);
  return res.data;
}

export async function listProductSSLCertificate(params) {
  const res = await api.post(`/accounting/owned/product/sslsertificate/list`, params);
  return res.data;
}

export async function listProductControlPanel(params) {
  const res = await api.post(`/accounting/owned/product/controlpanel/list`, params);
  return res.data;
}

export async function getDedicatedServer(id) {
  const res = await api.get(`/accounting/owned/product/dedicatedservers/get?owned_product_id=${id}`);
  return res.data;
}

export async function listOwnIPClass(type_id, product_id, params) {
  const res = await axios.post(`${ipApiUrl}/customer/list/owned/class-ip/records/for/customers?&product_type_id=${type_id}&customer_product_id=${product_id}` + "&token=" + localStorage.getItem("__mp_tk"), { content: params });
  return res.data;
}

export async function listOwnIPClassOld(type_id, product_id) {
  const res = await axios.get(`${ipApiUrl}/list/customer/owned/class-ip/records?&product_type_id=${type_id}&customer_product_id=${product_id}` + "&token=" + localStorage.getItem("__mp_tk"));
  return res.data;
}


export async function updateProjectOwnProduct(project_id, type_id, product_id) {
  const res = await api.put(`/project/update/for/owned/product?project_id=${project_id}&product_type_id=${type_id}&owned_product_id=${product_id}`);
  return res.data;
}

export async function listUnusedIP(class_id, skip = 0, limit = 100) {
  const res = await axios.get(`${ipApiUrl}/address/get/by/class_id/all/for/customers?class_id=${class_id}&skip=${skip}&limit=${limit}` + "&token=" + localStorage.getItem("__mp_tk"));
  return res.data;
}

export async function updateIPAddress(id, params) {
  const res = await axios.put(`${ipApiUrl}/address/update/for/customers?ip_id=${id}` + "&token=" + localStorage.getItem("__mp_tk"), params);
  return res.data;
}


export async function listVPNUser(type_id, product_id) {
  const res = await api.get(`/accounting/list/customer/vpn/user?product_type_id=${type_id}&owned_product_id=${product_id}`);
  return res.data;
}


export async function updateProductNote(type_id, product_id, note) {
  const res = await api.put(`/accounting/update/customer/owned/product/note?product_type_id=${type_id}&owned_product_id=${product_id}&note=${note}`);
  return res.data;
}

export async function cancelProduct(params) {
  const res = await api.post(`/accounting/owned/product/cancel/request/create`, { content: params });
  return res.data;
}


export async function getVirtualServer(id) {
  const res = await api.get(`/accounting/owned/product/cloudservers/get?owned_product_id=${id}`);
  return res.data;
}

export async function getCloudServer(id) {
  const res = await api.get(`/accounting/owned/product/cloud/get?owned_product_id=${id}`);
  return res.data;
}

export async function getColocation(id) {
  const res = await api.get(`/accounting/owned/product/colocation/get?owned_product_id=${id}`);
  return res.data;
}

export async function getMailServer(id) {
  const res = await api.get(`/accounting/owned/product/mailhosting/get?owned_product_id=${id}`);
  return res.data;
}

export async function getSSLCertificate(id) {
  const res = await api.get(`/accounting/owned/product/sslsertificate/get?owned_product_id=${id}`);
  return res.data;
}


export async function getControlPanel(id) {
  const res = await api.get(`/accounting/owned/product/controlpanel/get?owned_product_id=${id}`);
  return res.data;
}


export async function getDomain(id) {
  const res = await api.get(`/accounting/owned/product/domains/get?owned_product_id=${id}`);
  return res.data;
}

export async function getHosting(id) {
  const res = await api.get(`/accounting/owned/product/webhostings/get?owned_product_id=${id}`);
  return res.data;
}


export async function customerProductTransferRequest(product_type_id, owned_product_id, transferred_customer_code) {
  const res = await api.post(`/accounting/add/customer/owned/product/transfer/request?product_type_id=${product_type_id}&owned_product_id=${owned_product_id}&to_customer_code=${transferred_customer_code}`);
  return res.data;
}


export async function checkCustomerProductTransferRequest(product_type_id, owned_product_id, transferred_customer_code, transfer_code) {
  const res = await api.put(`/accounting/approve/customer/owned/product/transfer/request?product_type_id=${product_type_id}&owned_product_id=${owned_product_id}&transfer_code=${transfer_code}`);
  return res.data;
}


export async function updateDomainDns(owned_product_id, params) {
  const res = await api.put(`/accounting/update/customer/owned/domain/dns?owned_product_id=${owned_product_id}`, params);
  return res.data;
}

export async function updateDomainWhois(owned_product_id, params) {
  const res = await api.put(`/accounting/update/customer/owned/domain/whois?owned_product_id=${owned_product_id}`, params);
  return res.data;
}

export async function updateProfessionalDNS(owned_product_id, params) {
  const res = await api.put(`/accounting/update/customer/owned/domain/professional/dns?owned_product_id=${owned_product_id}`, params);
  return res.data;
}


export async function listServiceInvoices(owned_product_id, product_type_id) {
  const res = await api.get(`/accounting/proforma/invoices/get/owned/product?owned_product_id=${owned_product_id}&product_type_id=${product_type_id}`);
  return res.data;
}

export async function createCSR(params) {
  const res = await api.post(`/accounting/ssl/create/csr`, { content: params });
  return res.data;
}

export async function createCRT(params) {
  const res = await api.post(`/accounting/ssl/add/order`, { content: params });
  return res.data;
}

export async function sslCheckStatus(api_order_id, hostname) {
  const res = await api.post(`/accounting/ssl/check/status?api_order_id=${api_order_id}&common_name=${hostname}`);
  return res.data;
}

export async function changeProductAutoRenewal(status, product_type_id, owned_product_id) {
  const res = await api.put(`/accounting/update/customer/owned/product/auto/renewal?status=${status}&product_type_id=${product_type_id}&owned_product_id=${owned_product_id}`);
  return res.data;
}

export async function listProductIP(params) {
  const res = await api.post(`/accounting/owned/product/ip/list`, params);
  return res.data;
}

export async function getIP(owned_product_id) {
  const res = await api.get(`/accounting/owned/product/ip/get?owned_product_id=${owned_product_id}`);
  return res.data;
}

export async function listProductSupport(params) {
  const res = await api.post(`/accounting/owned/product/support/list`, params);
  return res.data;
}

export async function getSupport(owned_product_id) {
  const res = await api.get(`/accounting/owned/product/support/get?owned_product_id=${owned_product_id}`);
  return res.data;
}

export async function listProductIYS(params) {
  const res = await api.post(`/accounting/owned/product/iys/list`, params);
  return res.data;
}

export async function getIYS(owned_product_id) {
  const res = await api.get(`/accounting/owned/product/iys/get?owned_product_id=${owned_product_id}`);
  return res.data;
}

export async function listProductFirewall(params) {
  const res = await api.post(`/accounting/owned/product/firewall/list`, params);
  return res.data;
}

export async function getFirewall(owned_product_id) {
  const res = await api.get(`/accounting/owned/product/firewall/get?owned_product_id=${owned_product_id}`);
  return res.data;
}

export async function listProductProxy(params) {
  const res = await api.post(`/accounting/owned/product/proxy/list`, params);
  return res.data;
}

export async function getProxy(owned_product_id) {
  const res = await api.get(`/accounting/owned/product/proxy/get?owned_product_id=${owned_product_id}`);
  return res.data;
}

//#endregion


//#region Accounting

export async function getBasketCount() {
  const res = await api.get(`/accounting/get/basket/count`);
  return res.data;
}

export async function getProformaList(page = 1, limit = 25, params) {
  const res = await api.post(`/accounting/proforma/invoices/list?page=${page}&limit=${limit}`, { content: params });
  return res.data;
}

export async function getProforma(pf_no, currencycode, payment_method) {
  let currency = "";
  if (currencycode === "₺") {
    currency = "TL";
  } else if (currencycode === "$") {
    currency = "USD";
  } else if (currencycode === "€") {
    currency = "EURO";
  } else {
    currency = "TL";
  }

  const res = await api.get(
    `/accounting/proforma/invoices/get?proforma_invoice_no=${pf_no}&currencycode=${currency}${(payment_method && payment_method !== 0) ? `&payment_method=${payment_method}` : ""}`
  );
  return res.data;
}

export async function getProformaDetail(pf_no, currencycode, payment_method) {
  let currency = "";
  if (currencycode === "₺") {
    currency = "TL";
  } else if (currencycode === "$") {
    currency = "USD";
  } else if (currencycode === "€") {
    currency = "EURO";
  } else {
    currency = "TL";
  }

  const res = await api.get(
    `/accounting/proforma/invoices/detail?proforma_invoice_no=${pf_no}&currencycode=${currency}${(payment_method && payment_method !== 0) ? `&payment_method=${payment_method}` : ""}`
  );
  return res.data;
}

export async function postGarantiHtml(values) {
  const res = await api.post(`/accounting/get/garanti/html`, values);
  return res.data;
}

export async function postPaypalHtml(value) {
  const res = await api.post(`/accounting/get/paypal/html`, value);
  return res.data;
}


export async function paymentSuccessPaypal(info) {
  const res = await api.post(`/accounting/payment/success/paypal`, { content: info });
  return await res.data;
}


export async function payBasket(
  values,
  payment_method,
  currencyCode,
  is_partial = false
) {
  const res = await api.post(
    `/accounting/pay/proforma/invoice?payment_method=${payment_method}&currency_code=${currencyCode}&is_partial=${is_partial}`,
    values
  );
  return res.data;
}

export async function getCurrencyRates() {
  const res = await axios.get(`${frontpageApi}cache/frontpage/currency/rates/get`);
  return (res.data);
}

export async function multiplePayProforma(values, payment_method, currencyCode) {
  const res = await api.post(`/accounting/pay/selected/proforma/invoices?payment_method=${payment_method}&currency_code=${currencyCode}`, values);
  return res.data;
}

export async function getCompanyInformationsList() {
  const res = await api.get(`/accounting/list/company`);
  return res.data;  //makdos şirket bilgilerini çekmek için
}

export async function getTransactionList(page = 1, limit = 25, params) {
  const res = await api.post(`/accounting/transaction/list?page=${page}&limit=${limit}`, { content: params });
  return res.data;
}

export async function multiplePaymentProformaGet(proforma_ids) {
  const res = await api.post(`/accounting/get/selected/proforma/invoices`, proforma_ids);
  return res.data;
}

export async function getPaymentMethodList() {
  const res = await api.get(`/accounting/list/payment/method`);
  return res.data;
}

export async function getPaymentMethodListAddCredit() {
  const res = await api.get(`/accounting/add/credit/get/payment/methods`);
  return (res.data);
}

export async function addCredit(payment_method, currency_code, credit_amount, project_id) {
  const res = await api.post(`/accounting/add/credit?payment_method=${payment_method}&currency_code=${currency_code}&credit_amount=${credit_amount}&project_id=${project_id}`);
  return (res.data);
}

//#endregion